import React, { useContext } from "react";
import { FormContext } from "../../../contexts/FormOnlyEmail.context";
import InputField from "../../Forms/FormFields/InputField";
import emailOnlyStyles from "../EmailOnlyForm/EmailOnlyForm.module.scss";
import { EmailOnlyFormProps, IterableDataType } from "@types";
import { iterableSubmit } from "../../../utils/iterableSubmit";
import { getFormattedTime } from "../../../assets/util-scripts/helpers";
import DropDownWithArrow from "@components/Dropdowns/DropDownWithArrow";
import { useSignUpFormNavigate } from "@hooks/useSignUpFormNavigate";
import { leadCapture } from "@utils/segment/leadCapture";

const EmailOnlyFormWithDropdown: React.FC = () => {
  const {
    handleBlur,
    handleChange,
    email,
    placeholder,
    inputError,
    externalClassNames,
    hideInput,
    buttonText,
  }: EmailOnlyFormProps = useContext(FormContext);

  const signUpFormNavigate = useSignUpFormNavigate();

  const submitEmailData: IterableDataType = {
    email,
    SIGN_UP_SOURCE: "Website - Sign Up CTA",
    startTrialFlowAt: getFormattedTime(),
  };

  const handleDropdownSelection = () => {
    const analyticsData = {
      text: buttonText,
      email,
    };

    leadCapture(analyticsData);
    iterableSubmit(submitEmailData);

    signUpFormNavigate(email);
  };

  return (
    <form
      data-testid="email-only-form"
      onSubmit={e => e.preventDefault()}
      className={`${emailOnlyStyles.emailOnlyFormContainer} ${externalClassNames}`}
    >
      {!hideInput && (
        <InputField
          inputError={inputError}
          data-testid="modal-form-input"
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          type="text"
        />
      )}

      <DropDownWithArrow
        label="Start for free"
        email={email}
        additionalClickEvents={handleDropdownSelection}
        preventRedirect
      />
    </form>
  );
};

export default EmailOnlyFormWithDropdown;

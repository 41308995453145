import builder from "@builder.io/react";

export const externalRedirect = async () => {
  const redirectList = await builder.getAll("extole-referrals-redirect", {
    fields: "data",
    options: {
      cachebust: true,
    },
  });

  return redirectList;
};
